<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <van-nav-bar title="门店预约" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div style="margin-top:46px;background: #fff;">
      <!-- <div class="shuoming">
        预约时间
        <span style="color: #FFA500;">20分钟内</span>到店可优先安装，如预约的工位正在施工，
        <span style="color: #FFA500;">可优先排队</span>（线上开发部分预约工位，如果全部约满，可直接到店取号排队）
      </div>-->
      <span></span>
      <div class="xuan_a" style="margin-top: 12px;">
        <span class="xin_b left">
          <img class="mendiantu" v-if="shangjia.doorPic" :src="shangjia.doorPic" alt />
          <img class="mendiantu" v-else src="../../assets/img/mendian.png" alt />
        </span>
        <div class="left dian_ha">
          <div class="dian_h1">{{shangjia.name}}</div>
          <div class="dian_h2">
            <van-icon name="underway-o" style="vertical-align: middle" />
            <span style="vertical-align: middle;margin-left: 2px;">预约时间：</span>
            <span style="vertical-align: middle">暂无预约</span>
          </div>
          <div class="dian_h3">
            <van-icon name="contact" style="vertical-align: middle" />
            <span style="vertical-align: middle;margin-left: 2px;">预约客户：</span>
            <span style="vertical-align: middle">{{userInfo.realName}}</span>
          </div>
        </div>
      </div>
      <div style="height: 8px;background: #f5f5f5;"></div>
      <van-tabs @click="qiehuan">
        <van-tab :name="item.nianyue" v-for="(item,index) in list" :title="item.yue +' ' + item.week" :key="index">
          <van-row gutter="10" class="sjs">
            <van-col class="shijianc" span="6" v-for="(item,idx) in SJlist" :key="idx" @click.stop="sjclick(item)">
              <div class="shijian" :class="item.timeSlot == timeSlot?'activc':''" :style="{color:(item.station==0?'#999':''),background:(item.station==0 || item.isok == true?'#f3f3f3':'')}">
                <p>{{item.timeSlot}}</p>
                <p class="yuyue" v-if="item.station > 0">剩余工位{{item.station}}</p>
                <p class="yuyue" v-else style="color:#999">约 满</p>
              </div>
            </van-col>
          </van-row>
        </van-tab>
      </van-tabs>
      <div class="xianmu" v-if="isok">
        <span>预约项目：</span>
        <span class="xianmu_a activ" v-for="(item,idx) in station.itemName" :key="idx">{{item}}</span>
        <!-- <span class="xianmu_a">汽车保养</span>
        <span class="xianmu_a">汽车维修</span>-->
      </div>
      <div class="xianmu" v-if="carisok == 'true'">
        <span>预约项目：</span>
        <span class="xianmu_a activ">驹式标准洗车</span>
      </div>
      <div class="xianmuc">
        <div class="left" style="line-height: 14px;">备注：</div>
        <textarea class="left iptt" placeholder="请输入..." v-model="form.remark"></textarea>
      </div>
    </div>
    <div style="height: 60px;"></div>
    <van-goods-action>
      <van-goods-action-icon icon="phone-o" text="联系门店">
        <!-- <a :href="'tel:'+18318224189">联系门店</a> -->
        <a :href="'tel:'+shangjia.tel1">联系门店</a>
      </van-goods-action-icon>
      <van-goods-action-button type="danger" v-if="carisok == 'true'" text="立即预约" @click="yuyuecar" v-preventReClick />
      <van-goods-action-button type="danger" v-else text="立即预约" @click="yuyue" v-preventReClick />
    </van-goods-action>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import utils from "../../assets/utils";
import preventReClick from "../../assets/preventReClick";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  //   mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      list: [],
      SJlist: [],
      xmid: 0,
      time2: 0,
      orderNumber: "",
      type: "",
      rowtype: "",
      carNo: "",
      applyItem: "",
      couponId: "",
      appointmentId: "",
      navigationId: "",
      navigationType: "",
      form: {
        remark: "",
        items: "",
        visitDate: "",
      },
      nianyue: null,
      shifen: "",
      timeSlot: 0,
      isok: this.$route.query.isok,
      carisok: null,
      station: JSON.parse(sessionStorage.getItem("station"))
        ? JSON.parse(sessionStorage.getItem("station"))
        : null,
      userInfo: JSON.parse(localStorage.getItem("userInfo"))
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "",
      shangjia: JSON.parse(sessionStorage.getItem("shangjia"))
        ? JSON.parse(sessionStorage.getItem("shangjia"))
        : "",
      chexin: JSON.parse(sessionStorage.getItem("chexin"))
        ? JSON.parse(sessionStorage.getItem("chexin"))
        : "",
      active: "0",
    };
  },
  methods: {
    handleDate(value) {
      let date = new Date(value);
      let diffValue = new Date().getTime() - date.getTime(); // 时间差
      let mValue = diffValue / (1000 * 60); // 分
      let hValue = diffValue / (1000 * 60 * 60); // 小时
      let dayValue = diffValue / (1000 * 60 * 60 * 24); // 天
      let result = "";
      if (date.getFullYear() !== new Date().getFullYear()) {
        // 不同年
        result = time.substring(0, 16);
      } else {
        // 同年
        if (dayValue > 6) {
          // 时间差大于一周
          result = time.substring(5, 16);
        } else if (dayValue >= 1 && dayValue <= 6) {
          // 时间差一天之外 一周之内
          result = "";
        } else if (hValue >= 1 && hValue <= 23) {
          result = parseInt(hValue) + "小时前";
        } else if (mValue >= 1 && mValue <= 59) {
          result = parseInt(mValue) + "分钟前";
        } else if (diffValue >= 0 && diffValue <= mValue) {
          result = "刚刚";
        }
      }
      return result;
    },
    yuyue() {
      if (this.shifen == "") {
        this.$toast("请先选择预约时间!");
        return;
      }
      var items = "";
      if (this.type == 1) {
        items = "换轮胎";
      } else if (this.type == 6) {
        items = "做保养 ";
      } else if (this.type == 7) {
        items = "美容洗车";
      } else if (this.type == 8) {
        items = "玩车改装";
      } else if (this.type == 99) {
        items = this.station.itemName.join(",");
      } else if (this.type == 3) {
        items = this.applyItem;
      } else if (this.type == 4) {
        items = this.applyItem;
      }
      var data = {};
      if (this.type == 3 || this.type == 4) {
        console.log("走了这");
        data = {
          carNo: this.carNo,
          appointmentId: this.station
            ? this.station.appointmentId
            : this.appointmentId,
          comboItemList: this.station ? this.station.comboItemList : [],
          couponId: this.couponId,
          garageName: this.shangjia.name,
          gid: this.shangjia.id,
          items: items,
          number: "",
          phone: this.userInfo.phone,
          remark: this.form.remark,
          status: 1,
          navigationId: this.type == 4 ? this.navigationId : "",
          navigationType: this.type == 4 ? this.navigationType : "",
          type: this.rowtype,
          uid: this.userInfo.id,
          userName: this.userInfo.realName,
          visitDate: this.nianyue + " " + this.shifen,
        };
      } else {
        data = {
          carNo: this.carNo,
          appointmentId: this.appointmentId,
          comboItemList: this.station ? this.station.comboItemList : [],
          garageName: this.shangjia.name,
          gid: this.shangjia.id,
          items: items,
          number: this.orderNumber,
          phone: this.userInfo.phone,
          remark: this.form.remark,
          status: 1,
          type: this.rowtype,
          uid: this.userInfo.id,
          userName: this.userInfo.realName,
          visitDate: this.nianyue + " " + this.shifen,
        };
      }

      orderModel
        .yuyuexinzen(data)
        .then((e) => {
          this.$toast("预约成功！");
          setTimeout(() => {
            // this.$router.push({
            //   name: "make_list"
            // });
            this.$router.go(-1);
          }, 1000);
        })
        .catch(() => loading.clear());
    },
    //首页预约洗车
    yuyuecar() {
      console.log("首页进来预约洗车");
      if (this.shifen == "") {
        this.$toast("请先选择预约时间!");
        return;
      }

      const data = {
        carNo: this.chexin.carNo,
        garageName: this.shangjia.name,
        gid: this.shangjia.id,
        items: "驹式标准洗车",
        appointmentId: this.appointmentId,
        phone: this.userInfo.phone,
        remark: this.form.remark,
        status: 1,
        type: 5,
        uid: this.userInfo.id,
        userName: this.userInfo.realName,
        visitDate: this.nianyue + " " + this.shifen,
      };
      // const loading = this.$toast.loading({
      //   mask: true,
      //   duration: 0,
      //   message: "加载中..."
      // });
      orderModel
        .yuyuexinzen(data)
        .then((e) => {
          // loading.clear();
          this.$toast("预约成功！");
          setTimeout(() => {
            // this.$router.go(-1);
            this.$router.push({
              path: "make_list",
              query: {
                carisok: true,
              },
            });
          }, 1200);
        })
        .catch(() => loading.clear());
    },
    onLoad() {
      this.nianyue = this.list[0].nianyue;
      console.log(this.station);
      console.log(this.appointmentId);
      const data = {
        gid: this.shangjia.id,
        appointmentId: this.station
          ? this.station.appointmentId
          : this.appointmentId,
        // gid: 1,
        time: this.list[0].nianyue,
      };

      // const loading = this.$toast.loading({
      //   mask: true,
      //   duration: 0,
      //   message: "加载中..."
      // });
      orderModel
        .station(data)
        .then((e) => {
          // loading.clear();

          var data = e.data;
          for (var i in data) {
            var t = this.list[0].nianyue + " " + data[i].timeSlot;

            var time = new Date(t.replace(/-/g, "/")).getTime() / 1000;
            // var t = parseInt(
            //   new Date(
            //     this.list[0].nianyue + " " + data[i].timeSlot
            //   ).getTime() / 1000
            // );
            if (this.time2 > time) {
              data[i].isok = true;
            }
          }
          console.log(data);
          this.SJlist = e.data;
        })
        .catch(() => loading.clear());
    },
    sjclick(row) {
      // console.log(row);
      // this.fenmiao = row.
      if (row.station <= 0 || row.isok) {
        return;
      }
      if (row.timeSlot == null) {
        this.shifen = "00:00:00";
      } else {
        this.shifen = row.timeSlot + ":00";
      }

      console.log(this.shifen);
      this.timeSlot = row.timeSlot;
    },
    xmclick(item) {
      this.form.items = item.name;
      this.xmid = item.id;
    },
    qiehuan(name, title) {
      console.log(this.station);
      console.log(this.appointmentId);
      this.xmid = 0;
      this.timeSlot = 0;
      this.form.items = "";
      this.nianyue = name;
      this.shifen = "";
      console.log(name);
      const data = {
        gid: this.shangjia.id,
        appointmentId: this.station
          ? this.station.appointmentId
          : this.appointmentId,
        time: name,
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      orderModel
        .station(data)
        .then((e) => {
          loading.clear();
          var data = e.data;
          if (this.list[0].nianyue == name) {
            for (var i in data) {
              var t = this.list[0].nianyue + " " + data[i].timeSlot;

              var time = new Date(t.replace(/-/g, "/")).getTime() / 1000;
              // var t = parseInt(
              //   new Date(
              //     this.list[0].nianyue + " " + data[i].timeSlot
              //   ).getTime() / 1000
              // );
              if (this.time2 > time) {
                data[i].isok = true;
              }
            }
          }

          this.SJlist = e.data;
        })
        .catch(() => loading.clear());
      // console.log(title);
    },
    etNowTimes() {
      let myDate = new Date();
      let wk = myDate.getDay();
      let weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      let week = weeks[wk];
      console.log(week);
      this.nowWeek = week;
    },
    getDay(day) {
      var today = new Date();
      console.log(today);
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      console.log(targetday_milliseconds);
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      var shijian = tYear + "-" + tMonth + "-" + tDate;
      var date1 = new Date(shijian);
      let wk = date1.getDay();
      let weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      let week = weeks[wk];
      var yue = tMonth + "-" + tDate;
      var nianyue = tYear + "-" + tMonth + "-" + tDate;
      var lis = {
        yue: yue,
        nianyue: nianyue,
        week: week,
      };
      console.log(week);
      console.log(date1);
      //   return tYear + "-" + tMonth + "-" + tDate;
      this.list.push(lis);
      return tMonth + "-" + tDate + " " + week;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    back() {
      this.$router.go(-1);
    },
  },
  created() {
    // let time = parseInt(new Date("2022-09-27 11:00").getTime() / 1000);

    let time2 = parseInt(new Date().getTime() / 1000) + 7200;
    this.time2 = time2;
    // console.log("昨天：", this.getDay(-1));
    this.orderNumber = utils.getUrlKey("orderNumber");
    this.carNo = utils.getUrlKey("carNo");
    this.type = utils.getUrlKey("type");
    this.rowtype = utils.getUrlKey("rowtype");
    this.couponId = utils.getUrlKey("couponId");
    this.applyItem = utils.getUrlKey("applyItem");
    this.appointmentId = utils.getUrlKey("appointmentId");
    this.navigationId = utils.getUrlKey("navigationId");
    this.navigationType = utils.getUrlKey("navigationType");
    this.carisok = utils.getUrlKey("carisok");
    this.getDay(0);
    this.getDay(1);
    this.getDay(2);
    this.getDay(3);
    this.getDay(4);
    // this.getDay(5);

    this.onLoad();
    // console.log("今天：", this.getDay(2));
    // console.log("明天：", this.getDay(3));
    // this.etNowTimes()
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.xuan_a {
  font-size: 13px;
  overflow: hidden;
  //   line-height: 35px;
  margin: 5px 0;
}
.shijian {
  font-size: 12px;
  text-align: center;
  border: 1px solid #f5f5f5;
  padding: 6px 0;
  border-radius: 5px;
}
.shijian p {
  text-align: center;
  line-height: 16px;
}
.xianmu {
  font-size: 14px;
  padding: 0 12px;
  overflow: hidden;
}
.shuoming {
  padding: 8px 12px;
  font-size: 12px;
  line-height: 18px;
  background: #ffffe0;
  color: #333;
}
.xianmuc {
  font-size: 14px;
  padding: 0 12px;
  overflow: hidden;
  margin-top: 15px;
}

.iptt {
  width: 85%;
  height: 120px;
  border: 1px solid #999;
}
.xianmu .activ {
  background: #ff6347;
  color: #fff;
}
.shijianc .activc {
  background: red;
  color: #fff;
}
.activc p {
  color: #fff;
}
.xianmu_a {
  display: inline-block;
  border: 1px solid #f5f5f5;
  margin-left: 6px;
  font-size: 13px;
  padding: 0 5px;
  color: #666;
  border-radius: 4px;
}
.van-tab {
  // color: #000;
}
.yuyue {
  font-size: 10px;
  color: #666;
}
.van-row {
  margin: 15px 12px;
}
.xin_b {
  margin-left: 12px;
  font-size: 13px;
  margin-top: 12px;
}
.mendiantu {
  width: 80px;
  height: 70px;
}
.dian_h1 {
  font-size: 16px;
  font-weight: bold;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 17px;
}
.dian_h3 {
  color: #666;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.van-goods-action-button--last {
  border-radius: 20px;
}
.dian_h2 {
  color: #666;
  line-height: 14px;
  font-size: 12px;
  margin-top: 14px;
}
.dian_ha {
  margin-left: 10px;
  font-size: 12px;
  margin-top: 8px;
  width: 210px;
}
.shijianc:nth-child(n + 5) {
  margin-top: 5px;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
.van-tabs__wrap {
  border-bottom: 1px solid #f5f5f5;
}
.van-tab {
  font-weight: bold;
  flex-basis: 25% !important;
}
.van-goods-action-button--last {
  border-radius: 5px;
}
.van-tab__text--ellipsis {
  font-size: 12px;
}

// .van-tab__text{
//   font-size: 12px;
// }
</style>